<template>
  <div class="title">微生物检测/质控品分类</div>

  <bncc-list
          :list="list"
          :children="children"
          @handleclick="onClick"
          @handlesecclick="onClickSecond"
  ></bncc-list>
</template>

<script lang="ts">
  import { defineComponent, reactive, ref } from "vue";
  import BnccList from "@/components/Product/BnccList.vue";
  import api from "@/api/axios";
  import { useRouter } from "vue-router";
  export default defineComponent({
    name: "BnccCategory",
    components: {
      BnccList,
    },
    setup() {
      const list = ref([]);
      const children = ref<any[]>([]);

      api
              .get("/M/Product/getBnccClassify")
              .then((res) => {
                list.value = res.data.obj.one;
                res.data.obj.two.unshift({
                  id: 0,
                  categoryCnname: "全部",
                });
                children.value = res.data.obj.two;
              })
              .catch((err) => {
                console.log(err);
              });
      const router = useRouter();
      function onClick(classid: string) {
        if (classid == '9') {
          children.value = [
          {
            categoryCode: "ts1",
            categoryCnname: "细胞STR鉴定"
          },
          {
            categoryCode: "ts2",
            categoryCnname: "支原体检测"
          },
          {
            categoryCode: "ts3",
            categoryCnname: "细胞DNA提取"
          },
          {
            categoryCode: "ts4",
            categoryCnname: "菌种鉴定"
          },
          {
            categoryCode: "ts5",
            categoryCnname: "菌种代保藏"
          },
          {
            categoryCode: "ts6",
            categoryCnname: "菌种代培养"
          },
          {
            categoryCode: "ts7",
            categoryCnname: "菌种检测"
          },
          {
            categoryCode: "ts8",
            categoryCnname: "数字PCR定值服务"
          }

        ]
        } else {
          api
                  .get("/M/Product/getBnccClassify", {
                    params: {
                      type: 2,
                      id: classid,
                    },
                  })
                  .then((res) => {
                    res.data.obj.two.unshift({
                      id: 0,
                      categoryCnname: "全部",
                    });
                    children.value = res.data.obj.two;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
        }
      }
      function onClickSecond(id: string, type: number) {
         if (id == 'ts1') {
        router.push({
          name: "strAppraisal",
        });
        return false
      } else if (id == 'ts2') {
        router.push({
          name: "mycoplasma",
        });
        return false
      } else if (id == 'ts3') {
        router.push({
          name: "dnaExtraction",
        });
        return false
      } else if (id == 'ts4') {
        router.push({
          name: "identiFication",
        });
        return false
      }
      else if (id == 'ts5') {
        router.push({
          name: "preservation",
        });
        return false
      }
      else if (id == 'ts6') {
        router.push({
          name: "subculTure",
        });
        return false
      }
      else if (id == 'ts7') {
        router.push({
          name: "detection",
        });
        return false
      }
      else if (id == 'ts8') {
        router.push({
          name: "digitalpcr",
        });
        return false
      }else {
          router.push({
            name: "bnccProduct",
            query: {
              type,
              id,
            },
          });
        }
      }
      return {
        list,
        children,
        onClick,
        onClickSecond,
      };
    },
  });
</script>


<style lang="scss" scoped>
  .title {
    color: #333;
    text-align: center;
    font-size: 16px;
    line-height:1.5;
    padding: 7px 0;
    border-bottom: 1px solid #f4f4f4;
  }
</style>
